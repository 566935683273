<template>
  <div class="animated fadeIn">
    <b-row class="justify-content-md-center">
      <b-col sm="8">
        <b-form @submit.stop.prevent="onSubmit" @reset="onReset" v-if="show">
          <b-card header-tag="header" footer-tag="footer">
            <loading
              :active.sync="isLoading"
              :is-full-page="false"
              :color="this.$config.LOADER.color"
              :opacity="this.$config.LOADER.opacity"
              :background-color="this.$config.LOADER.backgroundColor"
              :z-index="10000"
            ></loading>
            <h5 slot="header" class="mb-0">
              <b-button
                size="sm"
                :to="{ name: 'admin.purchase.merchant.category.list' }"
                class="mr-2"
                ><i class="fa fa-chevron-left"></i> Буцах</b-button
              >
              <span v-if="this.$route.params.id > 0"
                >Засах:
                <strong class="text-primary">{{ form.name }}</strong></span
              >
              <span v-else>Шинээр нэмэх</span>
            </h5>

            <b-form-group
              id="input-group-parent"
              label="Parent:"
              label-for="input-parent"
            >
              <multiselect
                v-model="selectedParent"
                :options="parents"
                placeholder="Parent"
                label="name"
                track-by="id"
                :multiple="false"
              ></multiselect>
            </b-form-group>

            <b-form-group
              id="input-group-name"
              label="Нэр:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                type="text"
                required
                placeholder="Нэр"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-code"
              label="Код:"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model="form.code"
                required
                placeholder="Код"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-code"
              label="Icon:"
              label-for="input-code"
            >
              <b-form-input
                id="input-code"
                v-model="form.icon"
                placeholder="Icon"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-code"
              label="Эрэмбэ:"
              label-for="input-code"
            >
              <money
                id="input-fee"
                class="form-control"
                v-model="form.sortOrder"
                type="text"
                required
                placeholder=""
                suffix=""
                :precision="0"
              ></money>
            </b-form-group>

            <b-form-group id="input-group-4" class="mb-0">
              <b-form-checkbox value="true" v-model="form.isActive" switch
                >Идэвхтэй</b-form-checkbox
              >
            </b-form-group>

            <div slot="footer">
              <b-button type="submit" variant="primary">Хадгалах</b-button>
              <b-button type="reset" variant="danger" class="ml-3"
                >Шинэчлэх</b-button
              >
            </div>
          </b-card>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Merchant.Category.edit",
  data: function() {
    return {
      isLoading: false,
      form: {
        parentId: null,
        id: 0,
        name: "",
        code: "",
        icon: "",
        isActive: false,
        sortOrder: null,
      },
      selectedType: null,
      selectedParent: null,
      show: true,
      roles: [],
      types: [],
      parents: [],
      save_url: "AdminWebService/create_menu",
    };
  },
  watch: {
    selectedParent: {
      handler(selectedParent) {
        this.$data.form.parentId = selectedParent.id;
      },
      deep: true,
    },
  },
  created: function() {
    this.checkSession();
    // this.getRoles()
    // this.getParents()
    this.initData();
    this.loadData();
  },
  methods: {
    initData: function() {
      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL + "PurchaseWebService/get_merchant_type_list",
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.types = response.body.data;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );

      this.isLoading = true;
      this.$http
        .get(
          this.$config.API_URL +
            "PurchaseWebService/get_merchant_category_list",
          {
            params: {
              page: 1,
              per_page: 0,
              orderBy: "sort_order",
              parent_id: 0,
              columns: ["code", "name", "sort_order"].join(","),
            },
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;
            this.$data.parents = response.body.data;
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    loadData: function() {
      this.isLoading = true;
      this.$http
        .post(
          this.$config.API_URL + "PurchaseWebService/get_merchant_category",
          {
            data: JSON.stringify({
              token: this.$store.getters.token,
              email: this.$store.getters.email,
              id: this.$route.params.id,
            }),
          },
          {
            headers: this.$store.getters.httpHeader,
            emulateJSON: true,
          }
        )
        .then(
          (response) => {
            //console.log(response)
            this.isLoading = false;

            if (response.body) {
              this.$data.form = response.body;
              for (var i in this.$data.parents) {
                if (this.$data.parents[i].id == this.$data.form.parentId) {
                  this.$data.selectedParent = this.$data.parents[i];
                }
              }
            }
          },
          (response) => {
            this.isLoading = false;
            this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
          }
        );
    },
    onSubmit(evt) {
      this.isLoading = true;
      (this.$data.form.token = this.$store.getters.token),
        (this.$data.form.email = this.$store.getters.email),
        this.$http
          .post(
            this.$config.API_URL +
              "PurchaseWebService/merchant_category_data_save",
            { data: JSON.stringify(this.$data.form) },
            { headers: this.$store.getters.httpHeader, emulateJSON: true }
          )
          .then(
            (response) => {
              this.isLoading = false;
              this.showToast("Амжилттай", "Амжилттай хадгалагдлаа", "success");
              this.$router.push({
                name: "admin.purchase.merchant.category.list",
              });
            },
            (response) => {
              this.isLoading = false;
              this.showToast("Алдаа", "Алдаа гарсан байана", "danger");
            }
          );
    },
    onReset(evt) {
      evt.preventDefault();
      this.$data.form = {
        id: 0,
        name: "",
        code: "",
        icon: "",
        isActive: false,
        sortOrder: null,
        parentId: null,
      };
    },
  },
};
</script>
